import React from 'react'
import Layout from '../../components/Layout'
import InnerLayout from '../../components/InnerLayout'

export default function SignIn() {
    return (
        <Layout
            seoTitle="Kubevious :: Sign In"
            description="Join our Kubevious application"
        >
            <InnerLayout>Sign In</InnerLayout>
        </Layout>
    )
}
